import React, { useEffect, useRef } from 'react';
import { Avatar, Image, Typography } from 'antd';
import {
  MessageOutlined,
  EnvironmentOutlined,
  PictureOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import FlatList from 'flatlist-react/lib';
import useLocalStorage from './../../configs/localStorage';
const typingMessage = require('./../../assets/icons/typing_animation.gif');

const { Text } = Typography;

const ChatMessage = ({ messages, isTyping = false }) => {
  const flatListRef = useRef(null);
  const [auth] = useLocalStorage('auth', null);

  useEffect(() => {
    //scroll to bottom
    const chatMessages = document.getElementById('chat-messages');
    if (flatListRef.current && messages && chatMessages) {
      flatListRef.current.scrollTop = flatListRef.current.scrollHeight;
    }
  }, [messages]);
  return (
    <div
      ref={flatListRef}
      id="chat-messages"
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        height: '100%',
        padding: '10px',
      }}
    >
      <FlatList
        
        list={messages}
        key={messages?._id}
        renderItem={(item, index) => RenderItem(item, index, messages, isTyping, auth)}
      
        //scrollToBottom
      />
    </div>
  );
};
const RenderIsTyping = ({item, index, messages, isTyping,auth}) => {


  if (index == messages.length - 1) {
    return  isTyping ? (
      <div
      key={item?._id}
       style={{
         flexDirection:
           isTyping?.userType == 'agent' ? 'row-reverse' : 'row',
         display: 'flex',
       }}
     >
       <div
         style={{
           [isTyping?.userType == 'agent'
             ? 'marginRight'
             : 'marginLeft']: '66px',
 
           objectFit: 'cover',
           marginTop: '10px',
           marginBottom: '10px',
           backgroundColor:
             isTyping?.userType == 'agent' ? '#1677FF' : '#EEEEEE',
           color: isTyping?.userType == 'agent' ? '#fff' : '#000',
           borderRadius: '30px',
           padding: 10,
 
           // marginLeft: '10px',
         }}
       >
         {isTyping?.text && auth.user.isSuperAdmin ? (
           <span
             style={{
               wordBreak: 'break-all',
             }}
           >
             {isTyping.text}
           </span>
         ) : (
           ''
         )}
 
         <img
           style={{
             width: '45px',
             height: '20px',
             objectFit: 'cover',
             marginBottom: '-10px',
             color: 'red',
             borderRadius: '30px',
 
             // marginLeft: '10px',
           }}
           src={typingMessage}
         />
       </div>
       <div
         style={{
           width: '45px',
           height: '20px',
           objectFit: 'cover',
           marginBottom: '-10px',
 
           borderRadius: '30px',
 
           // marginLeft: '10px',
         }}
       ></div>
     </div>
   ) : null
         
   }
 };
const RenderItem = (item, index, messages, isTyping,auth) => {
  
 
  const previousMessage = index > 0 ? messages[index - 1] : null;
  const isCurrentUser = item.messageSource === 'agent'; // Assuming 'agent' is the current user
  const isSystemMessage =
    item.messageSource === 'system' || item.messageSource === 'bot';
  const isSameDay =
    previousMessage &&
    moment(item.createdAt).isSame(previousMessage.createdAt, 'day');
  const showDate = !previousMessage || !isSameDay;

  return (
    <div key={item?._id}>
      {/* <Text>{item?._id}</Text> */}
      {showDate && (
        <div style={{ textAlign: 'center', marginBottom: '10px' }}>
          <Text type="secondary">
            {moment(item.createdAt).format('DD/MM/YYYY')}
          </Text>
        </div>
      )}
      <Message
        message={item}
        isCurrentUser={isCurrentUser}
        isSystemMessage={isSystemMessage}
      />
      <RenderIsTyping item={item} index={index} messages={messages} isTyping={isTyping} auth={auth}/>
    </div>
  );
};

const Message = ({ message, isCurrentUser, isSystemMessage }) => {
  const messageContainerStyle = {
    display: 'flex',
    marginBottom: '10px',
    justifyContent: isCurrentUser
      ? 'flex-end'
      : isSystemMessage
      ? 'center'
      : 'flex-start',
  };

  const messageContentStyle = {
    maxWidth: '70%',
    padding: '10px',
    borderRadius: '10px',
    backgroundColor: isSystemMessage
      ? '#F9E3E2'
      : isCurrentUser
      ? '#EEF4FD'
      : '#F1F4F9',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  };

  const messageTextStyle = {
    color: isSystemMessage ? '#333333' : isCurrentUser ? '#30417B' : '#616377',
    fontSize: '14px',
    fontWeight: isSystemMessage ? 'normal' : isCurrentUser ? 'normal' : 'bold',
  };

  const messageTimeStyle = {
    marginTop: '5px',
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.45)',
    alignSelf: 'flex-end',
  };

  const renderMessageContent = () => {
    if (message.chatType === 'image') {
      return (
        <Image
          src={message.chatMessage}
          alt="Image"
          style={{ maxWidth: '20%', borderRadius: '10px' }}
        />
      );
    } else if (message.chatType === 'location') {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <EnvironmentOutlined
            style={{ marginRight: '5px', fontSize: '16px' }}
          />
          <Text style={{ ...messageTextStyle, fontSize: '12px' }}>
            {message.chatMessage}
          </Text>
        </div>
      );
    } else {
      return <Text style={messageTextStyle}>{message.chatMessage}</Text>;
    }
  };

  return (
    <div style={messageContainerStyle}>
      <div style={messageContentStyle}>
        {!isSystemMessage && (
          <div
            style={{
              marginBottom: '5px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Avatar
              size="small"
              src={message.createdByImage}
              style={{ marginRight: '5px' }}
            />
            <Text type="secondary" style={{ fontSize: '12px' }}>
              {message.createdByName}
            </Text>
          </div>
        )}
        {isSystemMessage && (
          <MessageOutlined
            style={{ marginRight: '5px', fontSize: '16px', color: '#333333' }}
          />
        )}
        {renderMessageContent()}
        <div style={messageTimeStyle}>
          {moment(message.createdAt).format('hh:mm A')}
        </div>
      </div>
    </div>
  );
};

export default ChatMessage;
