import { Fragment, useEffect, useReducer } from 'react';
import {
  Card,
  Tabs,
  Tag,
  Avatar,
  Button,
  Tooltip,
  Row,
  Col,
  Divider,
  Space,
  message,
  Typography,
  Popconfirm,
  Image,
} from 'antd';
import {
  PhoneOutlined,
  UserOutlined,
  TrophyOutlined,
  ClockCircleOutlined,
  DownOutlined,
  UpOutlined,
  PhoneFilled,
  MessageFilled,
} from '@ant-design/icons';
import {
  UilClipboardNotes,
  UilCommentAltLines,
  UilEdit,
  UilMap,
  UilShoppingCartAlt,
  UilUsdCircle,
} from '@iconscout/react-unicons';
import { useAxiosGetChat } from '../../configs/axios';
import CustomerInformationModal from '../home/CustomerInformationModal';
import CustomerVIPModal from '../home/CustomerVIPModal';
import moment from 'moment';
import {
  getLastCurrentStatus,
  showOrderPaymentType,
  statusTiming,
  getColor,
} from '../Chat/orderStatusConfig';
import motor from '../../assets/images/motor.png';
import OrderRefundModal from '../home/OrderRefundModal';
import OrderMapModalComponent from '../home/OrderMapModalComponent';
import ChangeOrderStatusModalComponent from '../home/ChangeOrderStatusModalComponent';
import EditOrderModal from '../home/EditOrderModal';
import OrderCartModalComponent from '../home/OrderCartModalComponent';
import DrawerChat from './DrawerChat';
const CUSTOMER_CHATS = 'customer_agent,customer_general';
const MERCHANT_CHATS = 'merchant_agent';
const DRIVER_CHATS = 'orders,couriers';

const format = 'hh:mm A';

const { Meta } = Card;

const ChatSidePanel = ({chatRoom}) => {

  const { data: ordersData, request: getOrdersForSelectedChatroom } =
    useAxiosGetChat('/orders/chatRoomOrders', {
      autoRun: false,
    });
  const initValue = {

    selectedOrder: null,
    customer: null,
    orderRefundModalVisible: false,
    orderCartModalVisible: false,
    editOrderModalVisible: false,
    changeOrderStatusModalVisible: false,
    orderMapModalVisible: false,
    showCustomerInfoModal: false,
    showModalVip: false,
    openChatModal: false,
    roomRef: null,
    roomType: null,
    roomName: null,
    showCollapse: false,


  };
  const onChatDrawerModalOpen = (open, roomRef, roomType, roomName) => {
    setState({
      openChatModal: open,
      roomRef: roomRef,
      roomType: roomType,
      roomName: roomName,
    });
  };
  const onCollapsing = (order) => {
    setState({
      showCollapse: order ? true : false,
      selectedOrder: order || null,
    });
  };
  const orderRefundModalOpen = (order) => {
    setState({
      orderRefundModalVisible: order ? true : false,
      selectedOrder: order || null,
    });
  };
  const orderCartModalOpen = (order) => {
    setState({
      orderCartModalVisible: order ? true : false,
      selectedOrder: order || null,
    });
  };
  const editOrderModalOpen = (order) => {
    setState({
      editOrderModalVisible: order ? true : false,
      selectedOrder: order || null,
    });
  };
  const changeOrderStatusModalOpen = (order) => {
    setState({
      changeOrderStatusModalVisible: order ? true : false,
      selectedOrder: order || null,
    });
  };
  const orderMapModalOpen = (order) => {
    setState({
      orderMapModalVisible: order ? true : false,
      selectedOrder: order || null,
    });
  };
  const showCustomerInfoModalOpen = (order) => {
    setState({
      showCustomerInfoModal: order ? true : false,
      selectedOrder: order || null,
    });
  };
  const showModalVipOpen = (order) => {
    setState({
      showModalVip: order ? true : false,
      customer: order || null,
    });
  };

  const [state, setState] = useReducer(
    (currentValue, newValues) => ({
      ...currentValue,
      ...newValues,
    }),
    initValue,
  );
  useEffect(() => {
    let intervalId;

    try {
      const runApiCall = chatRoom.roomRef && chatRoom.roomType;
      if (runApiCall) {
        getOrdersForSelectedChatroom({
          roomRef: chatRoom.roomRef,
          chatSource: chatRoom?.roomType,
          filterType: 'customer',
        });
      }

      intervalId = setInterval(() => {
        if (runApiCall) {
          getOrdersForSelectedChatroom({
            roomRef: chatRoom.roomRef,
            chatSource: chatRoom?.roomType,
            filterType: 'customer',
          });
        }
      }, 15000);
    } catch (err) {
      message.error(err.message);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [chatRoom]);

  const CustomerCard = () => {
    
    
    const getObj = ordersData?.list?.[0] || {};
    const {customer} = getObj;
    let customer_id = customer?.customer_id || 'id';
    let customerName = customer?.customer_name || '';
    let customerLastName = customer?.customer_last_name || '';
    let customerPhone = customer?.customer_phone || '';

    if (chatRoom?.roomType == 'customer_general') {
      customer_id = chatRoom?.roomRef || 'id';
      customerName = chatRoom?.roomName || '';
      customerLastName = '';
      customerPhone = chatRoom?.roomPhoneNumber || '';
    }
    return (
      <Row gutter={[14, 14]} justify="center" align="middle">
      <Col span={24}>
        <h1 style={{ textAlign: 'center', color: '#757575' }}>Customer Details</h1>
      </Col>
      <Col span={24}>
        <Card
          style={{ width: '100%' }}
          actions={[
            <Tooltip title="Copy phone number" key="copy">
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(customerPhone);
                  message.success('Phone number copied to clipboard');
                }}
                icon={<PhoneOutlined />}
              >
                Copy Phone
              </Button>
            </Tooltip>,
            <Tooltip title="Order Details" key="details">
              <Button
                onClick={() => {
                  setState({
                    selectedOrder: getObj,
                    showCustomerInfoModal: true,
                  });
                }}
                icon={<UserOutlined />}
              >
                Order History
              </Button>
            </Tooltip>,
            <Tooltip title="Make VIP" key="vip">
              <Button
                onClick={() => {
                  setState({
                    showModalVip: true,
                    customer: getObj?.customer,
                  });
                }}
                icon={<TrophyOutlined />}
              >
                Make VIP
              </Button>
            </Tooltip>,
            <Tooltip title="Chat Customer" key="chat">
              <Button
                onClick={() => {
                 

                  onChatDrawerModalOpen(true, customer_id, 'customer_general', customerName);

                }}
                icon={<MessageFilled />}
              >
                Chat
              </Button>
            </Tooltip>,
          ]}
        >
          <Meta
            avatar={<Avatar src="https://api.dicebear.com/7.x/miniavs/svg?seed=8" />}
            title={`${customerName} ${customerLastName} (${customer_id})`}
            description={customerPhone}
          />
        </Card>
      </Col>
    </Row>


    );
  };

  const RenderMiniCurrentStatus = ({ status, align }) => {
    if (
      status &&
      status.title &&
      status.time &&
      status.bgColor &&
      status.textColor
    ) {
      // duration of status.time as minutes to render
      //status.time is this formar "2024-05-01T17:27:55.000Z"
      // use the timezone, use GMT+3
      const time = moment(status.time).format('YYYY-MM-DD HH:mm:ss').toString();

      const duration = Math.ceil(
        moment.duration(moment().diff(moment(time))).asMinutes(),
      );
      return (
        <div
          style={{
            width: '100%',
            alignContent: align,
            alignItems: align,
            justifyContent: align,
            display: 'flex',
          }}
        >
          <span
            style={{
              alignSelf: align,
              alignItems: 'center',
              textAlign: 'center',
              alignContent: 'center',
              backgroundColor: status.bgColor,
              borderRadius: 30,
              color: status.textColor,
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 2,
              paddingBottom: 2,
              width: 'auto',
            }}
          >
            <span style={{ fontWeight: 'light', textTransform: 'capitalize' }}>
              {status.title}:{' '}
              <span style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>
                {duration}m
              </span>
            </span>
          </span>
        </div>
      );
    }
    return null;
  };

  const getDriverCurrentShift = (single) => {
    const shift = single?.driver?.shift || [];
    const currentDay = {
      start: 0,
      end: 0,
    };
    const today = moment();
    for (let i = 0; i < shift.length; i++) {
      const element = shift[i];
      if (element.day == today.format('dddd')) {
        const now = new Date();
        const currentHour = now.getHours();
        const currentMinute = now.getMinutes();
        const currentTimeInMinutes = currentHour * 60 + currentMinute;

        if (
          currentTimeInMinutes > element.time?.[0].from &&
          currentTimeInMinutes < element.time?.[0].to
        ) {
          const start = moment()
            .startOf('day')
            .add(element.time?.[0].from, 'minutes')
            .format('HH:mm');
          const end = moment()
            .startOf('day')
            .add(element.time?.[0].to, 'minutes')
            .format('HH:mm');
          currentDay.start = start;
          currentDay.end = end;
        }
      }
    }
    return currentDay;
  };
  const statusData = (type, time, badgeContent, bgColor, color, index) => {
    return (
      <div
        direction="vertical"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        key={index}
      >
        <p style={{ textTransform: 'capitalize' }}>{type}</p>
        <p style={{ margin: 0, fontWeight: 'bold', fontSize: 12 }}>{time}</p>
        <span
          style={{
            //width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: bgColor,
            color: color,
            borderRadius: 50,
            padding: 5,
            minHeight: 0,
            maxHeight: 25,
          }}
        >
          {badgeContent}m
        </span>
      </div>
    );
  };

  const renderCurrentStatus = (status) => {
    // duration of status.time as minutes to render
    //status.time is this formar "2024-05-01T17:27:55.000Z"
    // use the timezone, use GMT+3
    const time = moment(status.time).format('YYYY-MM-DD HH:mm:ss');

    const duration = Math.ceil(
      moment.duration(moment().diff(moment(time))).asMinutes(),
    );
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 10,
          backgroundColor: status.bgColor,
          padding: '5px 15px',
          borderRadius: 50,
          color: status.textColor,
          lineHeight: 1.1,
        }}
      >
        {status.icon}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <span style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>
            {status.title}
          </span>
          <span>{duration} mins</span>
        </div>
      </div>
    );
  };

  const checkStatus = (single) => {
    const status = getLastCurrentStatus(single, 'main');

    if (status) {
      return renderCurrentStatus(status);
    } else {
      return null;
    }
  };

  const RenderDriverInfo = ({ single, roomRef, roomType }) => {
    const currentShift = getDriverCurrentShift(single);
    const driverStatus = getLastCurrentStatus(single, 'driver');
    return single?.driver ? (
      <Col
        span={12}
        style={{
          alignContent: 'right',
          alignItems: 'right',
          justifyContent: 'right',
        }}
      >
        <div
          style={{
            display: 'flex',
            gap: 10,
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Image
            src={single?.driver?.avatarLink || motor}
            height={48}
            width={48}
            style={{
              borderRadius: '100%',
              objectFit: 'cover',
            }}
          />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Space>
              <a
                style={{
                  color: '#FF9900',
                  textDecoration: 'underline',
                  fontWeight: 'bold',
                }}
                onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(single?.driver?._id);
                  message.success('Copied to clipboard');
                }}
              >
                <Tooltip
                  title={
                    single?.driver?.firstName + ' ' + single?.driver?.lastName
                  }
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  {single?.driver?.firstName}
                </Tooltip>
              </a>
              <Tooltip
                title="Copy Phone Number"
                style={{
                  cursor: 'pointer',
                }}
              >
                <Button
                  onClick={() => {
                    navigator.clipboard.writeText(single?.driver?.phone);
                    message.success('Copied to clipboard');
                  }}
                  shape="circle"
                  style={{
                    background: 'black',
                    borderColor: 'black',
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  icon={<PhoneFilled size="8" />}
                />
              </Tooltip>
              <Tooltip
                title="Chat"
                style={{
                  cursor: 'pointer',
                }}
              >

                <Button

                
                  onClick={() => {


                    const { driver, orderId } = single;
                    onChatDrawerModalOpen(true, orderId, 'orders', driver.firstName)

                  }}
                  shape="circle"
                  style={{
                    background: 'black',
                    borderColor: 'black',
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  icon={<UilCommentAltLines size="18" />}
                />
              </Tooltip>
            </Space>
            <span
              style={{
                whiteSpace: 'nowrap',
                fontWeight: '500',
              }}
            >
              {currentShift.start} - {currentShift.end}
            </span>
          </div>
        </div>
        <RenderMiniCurrentStatus status={driverStatus} align="right" />
      </Col>
    ) : null;
  };

  const RenderMerchantInfo = ({ single, roomType, roomRef, chatRoom }) => {
    const merchantStatus = getLastCurrentStatus(single, 'merchant');
    return (
      <div>
        <div
          style={{
            display: 'flex',
            gap: 10,
            alignItems: 'center',
          }}
        >
          <Image
            src={single?.merchant?.merchant_logo}
            height={48}
            width={48}
            style={{
              borderRadius: '100%',
              objectFit: 'contain',
            }}
          />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Space>
              <a
                style={{
                  color: '#A72079',
                  textDecoration: 'underline',
                  fontWeight: 'bold',
                }}
                onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(single?.merchant?.merchant_id);
                  message.success('Copied to clipboard');
                }}
              >
                #M({single?.merchant?.merchant_id})
              </a>
              <Tooltip
                title="Copy Phone Number"
                style={{
                  cursor: 'pointer',
                }}
              >
                <Button
                  onClick={() => {
                    navigator.clipboard.writeText(
                      single?.merchant?.merchant_phone,
                    );
                    message.success('Copied to clipboard');
                  }}
                  shape="circle"
                  style={{
                    background: 'black',
                    borderColor: 'black',
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  icon={<PhoneFilled size="8" />}
                />
              </Tooltip>
              <Tooltip
                title="Chat"
                style={{
                  cursor: 'pointer',
                }}
              >
                <Button

                  onClick={
                    () => {

                      const { merchant, orderId } = single;
                      const { merchant_name } = merchant;
                      onChatDrawerModalOpen(true, orderId, MERCHANT_CHATS, merchant_name.en)




                    }
                  }
                  shape="circle"
                  style={{
                    background: 'black',
                    borderColor: 'black',
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  icon={<UilCommentAltLines size="18" />}
                />
              </Tooltip>
            </Space>
            <span
              style={{
                whiteSpace: 'nowrap',
                fontWeight: '500',
              }}
            >
              {single?.merchant?.merchant_name?.en}
            </span>
          </div>
        </div>
        <RenderMiniCurrentStatus status={merchantStatus} align="left" />
      </div>
    );
  };
  const OrderChips = ({ single }) => {
    return (
      <Space>
        {single?.lastStatusData &&
          !['completed', 'canceled'].includes(single?.orderStatus) ? (
          <div
            style={{
              display: 'flex',
              marginBottom: '10px',
            }}
          >
            <Tag
              style={{
                fontWeight: 'bold',
                textTransform: 'capitalize',
                marginBottom: '5px',
                color: 'black',
              }}
              color="#B2FFA3"
            >
              {`${single?.lastStatusData.order_change_to}(${single?.lastStatusData.order_status_min})`}
            </Tag>
          </div>
        ) : null}

        {single?.orderCompletedAt ? (
          <Tag color={single?.orderRejectedOnType ? 'red' : 'green'}>
            {`${(single?.orderRejectedOnType
              ? '🚫 Rejected: '
              : '✅ Completed: ') +
              (Math.ceil(
                moment
                  .duration(moment().diff(moment(single?.orderCompletedAt)))
                  .asMinutes(),
              ) || 0)
              } mins`}
          </Tag>
        ) : single?.orderCanceledAt ? (
          <Tag color="red">
            {`❌ Canceled: ${Math.ceil(
              moment
                .duration(moment().diff(moment(single?.orderCanceledAt)))
                .asMinutes(),
            ) || 0
              } mins`}
          </Tag>
        ) : single?.orderReadyAt ? (
          single?.orderStatus === 'ontheway' ? null : (
            <Tag color="red">
              {`✅ Ready: ${Math.ceil(
                moment
                  .duration(moment().diff(moment(single?.orderReadyAt)))
                  .asMinutes(),
              ) || 0
                } mins`}
            </Tag>
          )
        ) : single?.orderEstReadyAt ? (
          <Tag
            color={
              (Math.ceil(
                moment
                  .duration(moment(single?.orderEstReadyAt).diff(moment()))
                  .asMinutes(),
              ) || 0) >= 0
                ? 'blue'
                : 'red'
            }
          >
            {`${(Math.ceil(
              moment
                .duration(moment(single?.orderEstReadyAt).diff(moment()))
                .asMinutes(),
            ) || 0) >= 0
              ? '⏳ Est ready: '
              : '🙀 M late: '
              }${Math.abs(
                Math.ceil(
                  moment
                    .duration(moment(single?.orderEstReadyAt).diff(moment()))
                    .asMinutes(),
                ),
              ) || 0
              } mins`}
          </Tag>
        ) : null}
      </Space>
    );
  };

  const MerchantDriverInfo = ({ single, isShow, isOrderHistory }) => {
    
    return (
      <Space direction="vertical" style={{ width: '100%' }}>
        <div
          style={{
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            display: isShow ? 'flex' : 'none',
            flexDirection: 'column',
            gap: '10px 0',
          }}
        >
          <Row
            justify="space-between"
            style={{
              width: '100%',
            }}
          >
            <Col span={12}>
              <RenderMerchantInfo key={single?.orderId} single={single} />
            </Col>
            <RenderDriverInfo key={single?.driver?._id} single={single} />
          </Row>
          <hr style={{ width: '100%' }} />
          <Space
            style={{
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'left',
              display: 'flex',
            }}
          >
            {(() => {
              const statusTimeline = statusTiming(single);

              return statusTimeline
                ?.filter((a) => true || a.type == 'timeline')
                .map((st, index) => {
                  let time = moment(st.timestampData).format(format);
                  let checkLate = st.duration;
                  let color = st?.textColor;
                  let bgColor = getColor(0, st.threshold, checkLate);
                  let title = st?.title;

                  return statusData(
                    title,
                    time,
                    checkLate,
                    bgColor,
                    color,
                    index,
                  );
                });
            })()}
          </Space>
        </div>
        <Col
          span={24}
          style={{ margin: '10px 0', display: isShow ? 'flex' : 'none' }}
        >
          <OrderChips single={single} />
        </Col>
        {isOrderHistory ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            onClick={() =>
              onCollapsing((prev) => {
                if (prev) {
                  return null;
                }
                return single?.orderId;
              })
            }
          >
            {isShow ? <UpOutlined size={20} /> : <DownOutlined size={20} />}
          </div>
        ) : null}
      </Space>
    );
  };

  const SingleCard = ({ single, isShow, isOrderHistory }) => {
    const countDownDate = moment(single?.orderEstReadyAt);
    const checkCountDown = moment().diff(countDownDate, 'minutes');
    return (
      <Card
        title={
          <Space
            style={{
              alignItems: 'center',
            }}
          >
            <p
              style={{
                fontSize: 18,
                fontWeight: 'bold',
                margin: 0,
              }}
            >
              <Tooltip
                title={
                  <Button
                    type="primary"
                    onClick={() =>
                      window.open(
                        `${window.location.origin}/order-details?oid=${single?.orderId}`,
                        '_blank',
                        'noopener,noreferrer',
                      )
                    }
                  >
                    Show Order Detail
                  </Button>
                }
              >
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    navigator.clipboard.writeText(single?.orderId);
                    message.success('Copied to clipboard');
                  }}
                >
                  <span
                    style={{
                      fontWeight: 'bold',
                      textDecoration: 'underline',
                      fontSize: 18,
                      color: '#3274d1',
                    }}
                  >
                    #{single?.orderId}
                  </span>
                </a>
              </Tooltip>
            </p>
            <p
              style={{
                fontSize: 15,
                fontWeight: '200',
                margin: 0,
              }}
            >
              <ClockCircleOutlined />
              {` ${moment(single?.createdAt).format('YYYY-MM-DD HH:mm:ss') || 0
                } `}
            </p>
          </Space>
        }
        extra={
          <div
            style={{ display: 'flex', flexDirection: 'column' }}
            direction="vertical"
          >
            {checkStatus(single)}
          </div>
        }
        style={{ width: '100%', margin: '15px 0' }}
      >
        <Row gutter={[0, 0]} justify="space-between">
          <Col span={6}>
            <Space
              direction="vertical"
              style={{
                lineHeight: 1.1,
              }}
            >
              {single?.orderPaymentTypeId !== 0 &&
                single?.orderPaymentTypeId !== 2 &&
                !isOrderHistory ? (
                <Popconfirm
                  title="Do you want to convert payment to cash?"
                  onConfirm={() => convertPayment(single?.orderId)}
                >
                  <p
                    style={{
                      cursor: 'pointer',
                      fontSize: 15,
                      color: single?.orderPaymentId ? 'green' : 'red',
                      fontWeight: 'bold',
                    }}
                  >
                    {showOrderPaymentType(single?.orderPaymentTypeId)}
                  </p>
                </Popconfirm>
              ) : (
                <p
                  style={{
                    fontSize: 15,
                    color: single?.orderPaymentId ? 'green' : 'red',
                    fontWeight: 'bold',
                  }}
                >
                  {showOrderPaymentType(single?.orderPaymentTypeId)}
                </p>
              )}

              <span
                style={{
                  fontSize: 16,
                  fontWeight: 'bold',
                }}
              >
                {single?.orderTotal?.toLocaleString()} IQD
              </span>
            </Space>
          </Col>
          <Col span={3}>
            <Space
              direction="vertical"
              style={{
                lineHeight: 1.1,
              }}
            >
              <p>Address</p>
              <span
                style={{
                  fontWeight: 'bold',
                  textDecoration: 'underline',
                  fontSize: 18,
                  color: '#3274d1',
                }}
              >
                {single?.orderCity}
              </span>
            </Space>
          </Col>
          <Col offset={3} span={4}>
            {single?.orderStatus == 'completed' ||
              single?.orderStatus == 'canceled' ? null : (
              <Space
                direction="vertical"
                style={{
                  lineHeight: 1.1,
                }}
              >
                <p>{countDownDate?.format(format)}</p>
                <span
                  style={{
                    fontWeight: 'bold',
                    fontSize: 15,
                    padding: '5px 7px',
                    borderRadius: 50,
                    backgroundColor:
                      checkCountDown < 0
                        ? 'red'
                        : checkCountDown > 5
                          ? 'orange'
                          : 'grey',
                    color: 'white',
                  }}
                >
                  {checkCountDown}
                  Mins
                </span>
              </Space>
            )}
          </Col>
          <Col
            span={7}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                flexWrap: 'wrap',
                gap: '5px',
                width: 120,
              }}
            >
              <Tooltip title="Change Status">
                <Button
                  onClick={() => changeOrderStatusModalOpen(single)}
                  type="primary"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  icon={<UilClipboardNotes size="22" />}
                />
              </Tooltip>
              <Tooltip title="Map View">
                <Button
                  onClick={() => orderMapModalOpen(single)}
                  style={{
                    background: '#e67e22',
                    borderColor: '#e67e22',
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  icon={<UilMap size="22" />}
                />
              </Tooltip>
              <Tooltip title="Compensate/Refund">
                <Button
                  onClick={() => orderRefundModalOpen(single)}
                  style={{
                    background: '#8e44ad',
                    borderColor: '#8e44ad',
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  icon={<UilUsdCircle size="22" />}
                />
              </Tooltip>
              <Tooltip title="Order Cart">
                <Button
                  onClick={() => orderCartModalOpen(single)}
                  style={{
                    background: '#16a085',
                    borderColor: '#16a085',
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  icon={<UilShoppingCartAlt size="22" />}
                />
              </Tooltip>
              <Tooltip title="Edit Order">
                <Button
                  onClick={() => editOrderModalOpen(single)}
                  style={{
                    background: '#c0392b',
                    borderColor: '#c0392b',
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  icon={<UilEdit size="22" />}
                />
              </Tooltip>
            </div>
          </Col>

          <Col span={24}>
            <hr style={{ margin: '10px 0' }} />
          </Col>
          <Col span={24}>
            {
              <MerchantDriverInfo
                single={single}
                isShow={isShow}
                isOrderHistory={isOrderHistory}
              />
            }
          </Col>
        </Row>
      </Card>
    );
  };

  const renderedCardsOrder = (type = 'current') => {
    // Safety Check: Ensure ordersData exists and has a 'list' property
    if (!ordersData || !ordersData.list) {
      return []; // Or handle the case where data isn't available
    }

    const arr = ordersData.list;
    const orders = [];

    for (let i = 0; i < arr.length; i++) {
      const el = arr[i];
      if (
        (type === 'current' &&
          !['completed', 'canceled'].includes(el.orderStatus)) ||
        (type === 'history' &&
          ['completed', 'canceled'].includes(el.orderStatus))
      ) {
        orders.push(el);
      }
    }

    // Check if findIndex is a valid index within the 'orders' array
    if (orders.length > 1) {
      const findIndex = ordersData.list.findIndex(
        (si) => si?.orderId == chatRoom?.roomRef,
      );

      // Swap elements only if findIndex is valid and not 0
      if (findIndex > 0 && findIndex < orders.length) {
        [orders[0], orders[findIndex]] = [orders[findIndex], orders[0]];
      }
    }

    if (type === 'current' && orders?.length) {
      return orders?.map((single, index) => {
        const key = `card-${index}`;

        return (
          <Fragment key={key}>
            <div
              style={{
                position: 'relative',
                marginTop: 60,
              }}
            >
              <span
                style={{
                  position: 'absolute',
                  left: 20,
                  top: -33,
                  color: 'white',
                  backgroundColor: 'rgb(44, 40, 233)',
                  borderRadius: '10px 10px 0px 0px',
                  padding: '5px 10px',
                  fontSize: 20,
                  fontWeight: 'bold',
                }}
              >
                CURRENT
              </span>
              {SingleCard({ single, isShow: true, isOrderHistory: false })}
            </div>
          </Fragment>
        );
      });
    } else if (type === 'history' && orders?.length) {
      return (
        <Fragment>
          <Divider>Order History</Divider>
          {orders?.map((single, index) => {
            const key = `card-${index}`;
            return (
              <Fragment key={key}>
                {SingleCard({
                  single,
                  isShow: true,
                  isOrderHistory: true,
                })}
              </Fragment>
            );
          })}
        </Fragment>
      );
    }
  };

  return (
    <div
      style={{
        width: '100%',
        minWidth: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '10px',
        paddingRight: '10px',
      }}
    >
      <CustomerCard />
      {renderedCardsOrder('current')}
      {renderedCardsOrder('history')}
      {state.showCustomerInfoModal ? (
        <CustomerInformationModal
          status={state.showCustomerInfoModal}
          setStatus={showCustomerInfoModalOpen}
          selectedOrder={{ selectedOrder: state.selectedOrder }}
        />
      ) : null}
      {state.showModalVip ? (
        <CustomerVIPModal
          open={state.showModalVip}
          setOpen={showModalVipOpen}
          customer={state.customer}
        />
      ) : null}
      {state.orderRefundModalVisible && (
        <OrderRefundModal
          status={state.orderRefundModalVisible}
          setStatus={orderRefundModalOpen}
          selectedOrder={state.selectedOrder}
        />
      )}
      {state.orderCartModalVisible && (
        <OrderCartModalComponent
          status={state.orderCartModalVisible}
          setStatus={orderCartModalOpen}
          selectedOrder={state.selectedOrder}
          getAllOrders={getOrdersForSelectedChatroom}
        />
      )}
      {state.editOrderModalVisible && (
        <EditOrderModal
          status={state.editOrderModalVisible}
          setStatus={editOrderModalOpen}
          selectedOrder={state.selectedOrder}
          getAllOrders={state.chatroomRequest}
        />
      )}
      {state.changeOrderStatusModalVisible && (
        <ChangeOrderStatusModalComponent
          status={state.changeOrderStatusModalVisible}
          setStatus={changeOrderStatusModalOpen}
          selectedOrder={state.selectedOrder}
          getAllOrders={getOrdersForSelectedChatroom}
          ordersSearchFilters={() => { }}
          courierOrderStatus={state.selectedOrder.courierOrderStatus}
        />
      )}
      {state.orderMapModalVisible && (
        <OrderMapModalComponent
          status={state.orderMapModalVisible}
          setStatus={orderMapModalOpen}
          getAllOrders={getOrdersForSelectedChatroom}
          ordersSearchFilters={getOrdersForSelectedChatroom}
          selectedOrder={state.selectedOrder}
        />
      )}
      {state.openChatModal && (
        <DrawerChat
          onClose={() => onChatDrawerModalOpen(false)}
          roomRef={state.roomRef}
          roomType={state.roomType}
          roomName={state.roomName}
          height={'100%'}
          doNotKillSocket={true}
        />
      )}
    </div>
  );
};

export default ChatSidePanel;
